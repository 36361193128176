import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from 'axios';
import { API } from "../../API";

const loadUserFromLocalStorage= ()=>{
    try{
        const userLocal = JSON.parse(localStorage.getItem("user"));
        if (userLocal == null)
            return null;
        return userLocal;
    }
    catch(error){
        console.error("Could not load user data from localStorage", error);
        return null;
    }
}

const setAuthHeader = () => {
    const user = localStorage.getItem('user');
    const { token } = user ? JSON.parse(user) : {};
    // console.log('localStorage token ', token);
  
    return {
      headers: {
        Authorization: token ? `Bearer ${token}` : '', 
      },
    };
  };

// Seller registration & login


export const sellerRegistration= createAsyncThunk(
  'userRegistration',
  async (formData, {rejectWithValue})=>{
    try{
      const {data}= await axios.post(`${API}/auth/register`, formData, {withCredentials: true});
      console.log('userRegistration ',data);
      return data;      
    }
    catch (error) {
      if (error.response) {
        
        return rejectWithValue(error.response.data);
      } else if (error.request) {
        
        return rejectWithValue("No response received from server");
      } else {
        
        return rejectWithValue("Error setting up request");
      }
    }
  }
)

export const sellerLogin = createAsyncThunk(
  'sellerLogin',
  async (formData, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(`${API}/auth/login`, {username: formData.phone.value, password : formData.password}, {withCredentials: true});
      console.log('sellerLogin', data);
      // localStorage.setItem('user', JSON.stringify(data))
      return data;
    } catch (error) {
      if (error.response) {
        
        return rejectWithValue(error.response.data);
      } else if (error.request) {
        
        return rejectWithValue("No response received from server");
      } else {
        
        return rejectWithValue("Error setting up request");
      }
    }
  }
);

// Customer login & registration

export const userRegistration= createAsyncThunk(
  'userRegistration',
  async (formData, {rejectWithValue})=>{
    try{
      const {data}= await axios.post(`${API}/api/customer/register`, formData);
      console.log('userRegistration ',data);
      return data;      
    }
    catch (error) {
      if (error.response) {
        
        return rejectWithValue(error.response.data);
      } else if (error.request) {
        
        return rejectWithValue("No response received from server");
      } else {
        
        return rejectWithValue("Error setting up request");
      }
    }
  }
)

export const userLogin = createAsyncThunk(
  'userLogin',
  async (formData, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(`${API}/api/customer/login`, formData);
      console.log('userLogin', data);
      localStorage.setItem('user', JSON.stringify(data))
      return data;
    } catch (error) {
      if (error.response) {
        
        return rejectWithValue(error.response.data);
      } else if (error.request) {
        
        return rejectWithValue("No response received from server");
      } else {
        
        return rejectWithValue("Error setting up request");
      }
    }
  }
);

export const fetchUserData = createAsyncThunk(
  'fetchUserData',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`${API}/auth/me`,
        {
          withCredentials: true,
        }
      );
      return data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else if (error.request) {
        return rejectWithValue("No response received from server");
      } else {
        return rejectWithValue("Error setting up request");
      }
    }
  }
);

export const logoutUser = createAsyncThunk(
  'logoutUser',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(`${API}/auth/logout`,{},
        {withCredentials: true}
      );
      return data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else if (error.request) {
        return rejectWithValue("No response received from server");
      } else {
        return rejectWithValue("Error setting up request");
      }
    }
  }
);

export const updateUser = createAsyncThunk(
    'updateUser',
    async (formData, { rejectWithValue }) => {
        try {
            const { data } = await axios.put(`${API}/api/customer/update-details`,
              formData,
              setAuthHeader()
            );
            return data;
        } catch (error) {
            if (error.response) {
                return rejectWithValue(error.response.data);
            } else if (error.request) {
                return rejectWithValue("No response received from server");
            } else {
                return rejectWithValue("Error setting up request");
            }
        }
    }
);

export const fetchReferAndEarn = createAsyncThunk(
  'fetchReferAndEarn',
  async (pagination, { rejectWithValue }) => {
      try {
          const data = await axios.get(`${API}/refers?skip=${pagination.skip}&limit=${pagination.limit}`,{withCredentials: true}
          );
          return data;
      } catch (error) {
          if (error.response) {
              return rejectWithValue(error.response.data);
          } else if (error.request) {
              return rejectWithValue("No response received from server");
          } else {
              return rejectWithValue("Error setting up request");
          }
      }
  }
);

const user = createSlice({
  name: 'user',
  initialState: {
    user: null,
    isLoading: false,
    isError: false,
    isAuthLoading: false,
    errorMessage: null,
    isReferLoading: false,
    isReferError: false,
    referListCount: 0,
    referAmount: 0,
    referandearns: []
  },
  reducers: {
    logout: (state) => {
      state.user = null; 
      state.isError = false;
      state.errorMessage = null;
    },
    clearReferState: (state) => {
      state.referandearns = [];
      state.isReferLoading = false;
      state.referListCount = 0;
      state.isReferError = false;
      state.errorMessage = null;
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(fetchUserData.pending, (state) => {
      state.isLoading = true;
      state.isError = false;
      state.errorMessage = null;
    })
    .addCase(fetchUserData.fulfilled, (state, action) => {
      state.isLoading = false;
      state.user = action.payload.data;
    })
    .addCase(fetchUserData.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload.data || 'Fetch user data failed'; 
    });
    builder
    .addCase(fetchReferAndEarn.pending, (state) => {
      state.isReferLoading = true;
      state.isReferError = false;
      state.errorMessage = null;
      state.referListCount = 0;
      state.referAmount = 0;
    })
    .addCase(fetchReferAndEarn.fulfilled, (state, action) => {
      state.isReferLoading = false;
      state.referandearns = state.referandearns.concat(...action.payload.data.doc);
      state.referListCount = action.payload.data.count;
      state.referAmount = action.payload.data.amount;
    })
    .addCase(fetchReferAndEarn.rejected, (state, action) => {
      state.isReferLoading = false;
      state.isReferError = true;
      state.errorMessage = action.payload.data || 'Fetch refer and earn data failed'; 
    });
    builder
    .addCase(logoutUser.pending, (state) => {
      state.isLoading = true;
      state.isError = false;
      state.errorMessage = null;
    })
    .addCase(logoutUser.fulfilled, (state, action) => {
      state.isLoading = false;
      state.user = null;
    })
    .addCase(logoutUser.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = null || 'Fetch user data failed'; 
    });
    builder
    .addCase(sellerLogin.pending, (state) => {
      state.isAuthLoading = true;
      state.isError = false;
      state.errorMessage = null;
    })
    .addCase(sellerLogin.fulfilled, (state, action) => {
      state.isAuthLoading = false;
      state.user = action.payload.data;
    })
    .addCase(sellerLogin.rejected, (state, action) => {
      state.isAuthLoading = false;
      state.isError = true;
      state.errorMessage = action.payload.data || 'Fetch user data failed'; 
    });
    builder
    .addCase(sellerRegistration.pending, (state) => {
      state.isAuthLoading = true;
      state.isError = false;
      state.errorMessage = null;
    })
    .addCase(sellerRegistration.fulfilled, (state, action) => {
      state.isAuthLoading = false;
      state.user = action.payload.data;
    })
    .addCase(sellerRegistration.rejected, (state, action) => {
      state.isAuthLoading = false;
      state.isError = true;
      state.errorMessage = action.payload.data || 'Fetch user data failed'; 
    });
  },
});

export const { logout, clearReferState } = user.actions; 
export default user.reducer;
